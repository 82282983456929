import React from 'react'
import { Link } from 'react-router-dom'

const About = () => {
    return (
        <>
            <section className="about-section padding-top padding-bottom overflow-hidden">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6">
                            <div className="about-content">
                                <div className="section-header">
                                    <h2 className="section-header__title">About The Cee Lo</h2>
                                    <p>
                                        “Three Dice Celo 456”. This game
                                        is an adaptation of a well
                                        -known
                                        and very popular game played with
                                        three die, commonly known as
                                        “Cee
                                        -Lo”. Because of its urban
                                        roots in New York City and other
                                        major cities across America and
                                        the intensely fast paced,
                                        competitive nature of play, “Three
                                        Dice Celo 456” has a very popular
                                        following among all walks of life,
                                        cultures, including professional
                                        athletes, and Hip
                                        -Hop artists.

                                    </p>
                                </div>
                                <Link to="/" className="cmn--btn active">Know More</Link>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="aobut-thumb section-thumb">
                                <img src="./assets/images/about/thumb.png" alt="about" className="ms-lg-5" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="shapes">
                    <img src="./assets/images/about/shape.png" alt="about" className="shape shape1" />
                </div>
            </section>
        </>
    )
}

export default About