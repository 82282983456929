import React from 'react'
import Banner from '../../Component/Banner'
import About from '../../Component/About'
// import Game from '../../Component/Game'
// import WhyChooseUs from '../../Component/WhyChooseUs'
import HowTo from '../../Component/HowTo'

// import Investor from '../../Component/Investor'
// import Testimonial from '../../Component/Testimonial'
import { Play } from '../../Component/Play'

const Home = () => {
    return (
        <>
            <Banner />
            <About />
            {/* <Game /> */}
            <Play />
            {/* <WhyChooseUs /> */}
            <HowTo />
        
            {/* <Investor /> */}
            {/* <Testimonial /> */}
        </>
    )
}

export default Home