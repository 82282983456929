import React from "react";
import WhyChooseUs from "../../Component/WhyChooseUs";
// import Video1 from "../../assets/waheed/new.mp4";
// import Video2 from "../../assets/waheed/#3new.mp4";
// import Video3 from "../../assets/waheed/#4new.mp4";
import Zip from "../../assets/math report and additional rules and regulations.zip";
import Backimg from "../../assets/5-01.jpeg";
const Employee = () => {
  //   const handleDownload = () => {
  //     // Array of PDF file URLs
  //     const pdfURLs = ['./assets/pdfs/pdf.pdf', './assets/pdfs/AutomaticWinnerDecisions.XML', './pdf3.pdf']; // Add your PDF file URLs here

  //     // Loop through the array and trigger download for each file
  //     pdfURLs?.forEach((pdfURL, index) => {
  //       const link = document.createElement('a');
  //       link.href = pdfURL;
  //       link.download = `example${index + 1}.pdf`; // Set unique file names
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //     });
  //   };
  const handleDownload = () => {
    // Replace 'your-zip-file.zip' with the actual path to your zip file
    const zipFileURL = Zip;

    // Create a link element
    const link = document.createElement("a");

    // Set the href attribute to the zip file URL
    link.href = zipFileURL;

    // Set the download attribute with the desired file name
    link.download = "math report and additional rules and regulations.zip";

    // Append the link to the document
    document.body.appendChild(link);

    // Trigger a click event on the link to start the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <>
      <WhyChooseUs />
      <div
        style={{
          height: "100vh",
          width: "100%",
          marginTop: "30px",
          marginBottom: "30px",
          background: `url('${Backimg}') center`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center">How To Play</h1>
          </div>
          <div className="col-md-4">
            <iframe
              width="95%"
              height="300px"
              src="https://www.youtube.com/embed/jCDmr-cHNVs?si=oMGEOeCIDQvSTSOZ"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowfullscreen
            ></iframe>
          </div>
          <div className="col-md-4">
            <iframe width="95%" height="300px" src="https://www.youtube.com/embed/1f86Kt5Yy74?si=gr3bt585GmAn_BQ1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
          <div className="col-md-4">

            <iframe width="95%" height="300px" src="https://www.youtube.com/embed/boNs0F3MgvY?si=VxWaL74rVrNyC-Ax" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
          </div>
        </div>
      </div>
      <div className="button-wrapper d-flex justify-content-center mt-5">
        <button className="cmn--btn active btn--lg" onClick={handleDownload}>
          <i className="las la-download"></i> Download
        </button>
      </div>
      <p className="text-center my-4"><small className="text-warning">math report and additional rules and regulations</small></p>
    </>
  );
};

export default Employee;
