import React from 'react'
import './HowTo.css'
const HowTo = () => {
    return (
        <>
            <section className="how-section padding-top padding-bottom bg_img" style={{ background: "url(./assets/images/how/bg2.jpg)", marginTop: "100px" }}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <div className="section-header text-center">
                                <h2 className="section-header__title">Cee Lo Quick Tips</h2>
                                {/* <p>A casino is a facility for certain types of gambling. Casinos are often built combined with hotels, resorts.</p> */}
                            </div>
                        </div>
                    </div>

                    <div class="roadmap">
                        <div class="point">
                            <div class="point-index">1</div>
                            <div class="point-text">Prior to the point being established, when the shooter rolls an automatic winner the banker side wins and the player side will lose.</div>
                        </div>
                        <div class="point">
                            <div class="point-index">2</div>
                            <div class="point-text">Prior to the point being established, when the shooter rolls an automatic loser the player side wins and the banker side will lose.</div>
                        </div>
                        <div class="point">
                            <div class="point-index">3</div>
                            <div class="point-text">Once the point has been established and the next decision is an automatic winner, the player side will win and the banker side will lose</div>
                        </div>
                        <div class="point">
                            <div class="point-index">4</div>
                            <div class="point-text">Once the point has been established and the next decision is an automatic loser, the banker side will win and the player side will lose</div>
                        </div>
                        <div class="point">
                            <div class="point-index">5</div>
                            <div class="point-text">Once the point has been established and the next decision is a higher point than the established point, the player side will win and the banker side will lose</div>
                        </div>
                        <div class="point">
                            <div class="point-index">6</div>
                            <div class="point-text">Once the point has been established and the next decision is a lower point than the established point, the banker will win and the player side will lose</div>
                        </div>
                        <div class="point">
                            <div class="point-index">7</div>
                            <div class="point-text">When the point has been established and the next decision is the same point, this is a tie/push</div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}

export default HowTo