import React from 'react'

const WhyChooseUs = () => {
    return (
        <>
            <section className="why-section padding-top overflow-hidden" style={{ marginTop: '200px' }}>
                <div className="container">
                    <div className="row justify-content-between gy-5">
                        <div className="col-lg-12 col-xl-12">
                            <div class="section-header text-center">
                            <h2 className="text-center">1st game house booking all the action.</h2>
                            <br/>
                                <h2 class="section-header__title"> Rules of Play</h2>
                                <h6>BETS ON THE HOUSE
                                    OR
                                    PLAYER</h6>
                            </div>
                            <div className="row gy-4 gy-md-5 gy-lg-4 gy-xl-5">
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">01. NATURAL WINNERS</h4>
                                            <p>{`a) 4-5-6 combination`}</p>
                                            <p>{`b) Any three of a kind`}</p>
                                            <p>{`c) Any pair where the third die is a 6`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">02.NATURAL LOSERS</h4>
                                            <p>{`a) 1-2-3 combination`}</p>
                                            <p>{`b) Any pair where the third die is a 1 (except 1-1-1)`}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">03.BAR 1-1-6</h4>
                                            <p>If the banker rolls a 1-1-6 for the first decision,
                                                this would be considered a bar/push.
                                                Simply put he can’t win or lose</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">04.FAST FIELD</h4>
                                            <p>Fast field bet is a one-roll bet.<br />
                                                Here are the numbers that can win in the fast
                                                field (3,5,7,9,11,13 or 17)
                                                Any other numbers rolled is a loser</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">05.POINT BETS</h4>
                                            <p>Point bets are decision wagers made before the
                                                dice are rolled<br />
                                                For example: A player can bet all the dice will
                                                roll don’t 4 or better which are points of 3 or
                                                less<br />
                                                If a player rolls a decision of 4 or more he loses,
                                                3 or less he wins</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">06.DECISION BETS</h4>
                                            <p>Decision bets are side bets settled when one of
                                                the outcomes is a natural winner, a natural loser
                                                or a point is established<br />
                                                For example: If a player places his decision bet
                                                on 4,4,4 and another decision is rolled  besides
                                                4,4,4 the player loses his bet.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="offset-lg-4 col-lg-4 offset-lg-4 col-sm-12 w-100">
                                    <div className="why-item">

                                        <div className="why-item__content">
                                            <h4 className="title">07.ONE ROLL BET</h4>
                                            <p>One roll bets are settled immediately after the
                                                next roll.<br />
                                                For example: If a player bets a 1,2,3
                                                combination, and the dice rolls any other
                                                combination, the player will lose unless 1,2,3 is
                                                rolled</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className="shapes">
                    <img src="./assets/images/why/shape.png" alt="why" className="shape shape1" />
                </div>
            </section >
        </>
    )
}

export default WhyChooseUs