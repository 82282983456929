/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

const Banner = () => {
  return (
    <>
      <section
        className="banner-section bg_img overflow-hidden"
        style={{ background: "url('./assets/images/banner/bg.png') center" }}
      >
      
        <div className="container">
          <div className="banner-wrapper d-flex flex-wrap align-items-center justify-content-around">
            <div className="banner-content">
              <h1 className="banner-content__title">
                Play <span className="text--base">Cee Lo</span>
              </h1>
              <div className="button-wrapper">
                <a href="#play" className="cmn--btn active btn--lg">
                  <i className="las la-play"></i> Play Now
                </a>
              </div>
              <img
                src="./assets/images/banner/card.png"
                alt=""
                className="shape1"
              />
            </div>
            <div className="banner-thumb">
              <img src="./assets/images/logo.png" alt="banner" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
