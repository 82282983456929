import React, { useEffect } from 'react'
import './dice.css'

export const Play = () => {

    useEffect(() => {
        const elDiceOne = document.getElementById('dice1');
        const elDiceTwo = document.getElementById('dice2');
        const elDiceThree = document.getElementById('dice3');

        const rollDice = () => {
            elDiceOne.classList.add('d-block')
            elDiceOne.classList.remove('d-none')
            elDiceTwo.classList.add('d-block')
            elDiceTwo.classList.remove('d-none')
            elDiceThree.classList.add('d-block')
            elDiceThree.classList.remove('d-none')

            const diceOne = Math.floor(Math.random() * 6 + 1);
            const diceTwo = Math.floor(Math.random() * 6 + 1);
            const diceThree = Math.floor(Math.random() * 6 + 1);

            console.log(diceOne + ' ' + diceTwo+' '+diceThree);

            for (let i = 1; i <= 6; i++) {
                elDiceOne?.classList.remove('show-' + i);
                if (diceOne === i) {
                    elDiceOne?.classList.add('show-' + i);
                }
            }

            for (let k = 1; k <= 6; k++) {
                elDiceTwo?.classList.remove('show-' + k);
                if (diceTwo === k) {
                    elDiceTwo?.classList.add('show-' + k);
                }
            }
            for (let k = 1; k <= 6; k++) {
                elDiceThree?.classList.remove('show-' + k);
                if (diceThree === k) {
                    elDiceThree?.classList.add('show-' + k);
                }
            }
            setTimeout(() => {
                elDiceOne.classList.add('d-none')
                elDiceOne.classList.remove('d-block')
                elDiceTwo.classList.add('d-none')
                elDiceTwo.classList.remove('d-block')
                elDiceThree.classList.add('d-none')
                elDiceThree.classList.remove('d-block')
            }, 10000)
        };

        const handleRollClick = () => {
            rollDice();
        };

        document.getElementById('roll')?.addEventListener('click', handleRollClick);

        return () => {
            document.getElementById('roll')?.removeEventListener('click', handleRollClick);
        };
    }, []);

    return (
        <>
            <section className="dice-section" id='play' style={{ background: "url('./assets/images/board.png') center", backgroundSize: "100% 900px", backgroundRepeat: 'no-repeat' }}>
                <div class="game">
                    <div class="container">
                        <div id='dice1' class="dice dice-one d-none">
                            <div id="dice-one-side-one" class='side one'>
                                <div class="dot one-1"></div>
                            </div>
                            <div id="dice-one-side-two" class='side two'>
                                <div class="dot two-1"></div>
                                <div class="dot two-2"></div>
                            </div>
                            <div id="dice-one-side-three" class='side three'>
                                <div class="dot three-1"></div>
                                <div class="dot three-2"></div>
                                <div class="dot three-3"></div>
                            </div>
                            <div id="dice-one-side-four" class='side four'>
                                <div class="dot four-1"></div>
                                <div class="dot four-2"></div>
                                <div class="dot four-3"></div>
                                <div class="dot four-4"></div>
                            </div>
                            <div id="dice-one-side-five" class='side five'>
                                <div class="dot five-1"></div>
                                <div class="dot five-2"></div>
                                <div class="dot five-3"></div>
                                <div class="dot five-4"></div>
                                <div class="dot five-5"></div>
                            </div>
                            <div id="dice-one-side-six" class='side six'>
                                <div class="dot six-1"></div>
                                <div class="dot six-2"></div>
                                <div class="dot six-3"></div>
                                <div class="dot six-4"></div>
                                <div class="dot six-5"></div>
                                <div class="dot six-6"></div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div id='dice2' class="dice dice-two d-none">
                            <div id="dice-two-side-one" class='side one'>
                                <div class="dot one-1"></div>
                            </div>
                            <div id="dice-two-side-two" class='side two'>
                                <div class="dot two-1"></div>
                                <div class="dot two-2"></div>
                            </div>
                            <div id="dice-two-side-three" class='side three'>
                                <div class="dot three-1"></div>
                                <div class="dot three-2"></div>
                                <div class="dot three-3"></div>
                            </div>
                            <div id="dice-two-side-four" class='side four'>
                                <div class="dot four-1"></div>
                                <div class="dot four-2"></div>
                                <div class="dot four-3"></div>
                                <div class="dot four-4"></div>
                            </div>
                            <div id="dice-two-side-five" class='side five'>
                                <div class="dot five-1"></div>
                                <div class="dot five-2"></div>
                                <div class="dot five-3"></div>
                                <div class="dot five-4"></div>
                                <div class="dot five-5"></div>
                            </div>
                            <div id="dice-two-side-six" class='side six'>
                                <div class="dot six-1"></div>
                                <div class="dot six-2"></div>
                                <div class="dot six-3"></div>
                                <div class="dot six-4"></div>
                                <div class="dot six-5"></div>
                                <div class="dot six-6"></div>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <div id='dice3' class="dice dice-three d-none">
                            <div id="dice-two-side-one" class='side one'>
                                <div class="dot one-1"></div>
                            </div>
                            <div id="dice-two-side-two" class='side two'>
                                <div class="dot two-1"></div>
                                <div class="dot two-2"></div>
                            </div>
                            <div id="dice-two-side-three" class='side three'>
                                <div class="dot three-1"></div>
                                <div class="dot three-2"></div>
                                <div class="dot three-3"></div>
                            </div>
                            <div id="dice-two-side-four" class='side four'>
                                <div class="dot four-1"></div>
                                <div class="dot four-2"></div>
                                <div class="dot four-3"></div>
                                <div class="dot four-4"></div>
                            </div>
                            <div id="dice-two-side-five" class='side five'>
                                <div class="dot five-1"></div>
                                <div class="dot five-2"></div>
                                <div class="dot five-3"></div>
                                <div class="dot five-4"></div>
                                <div class="dot five-5"></div>
                            </div>
                            <div id="dice-two-side-six" class='side six'>
                                <div class="dot six-1"></div>
                                <div class="dot six-2"></div>
                                <div class="dot six-3"></div>
                                <div class="dot six-4"></div>
                                <div class="dot six-5"></div>
                                <div class="dot six-6"></div>
                            </div>
                        </div>
                    </div>
                    <div id='roll' class='roll-button'><button className='dice-button'>Roll dice!</button></div>
                </div>

            </section>
          
        </>
    )
}
