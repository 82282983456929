import { BrowserRouter,Routes,Route } from "react-router-dom";
import Layout from "./Layout/Layout";
import Home from "./Pages/Home/Home";
import Employee from "./Pages/Employee/Employee";
import Ceelorake from "./Pages/Ceelorake/Ceelorake";


function App() {
  return (
    <>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/employee" element={<Employee />} />
            <Route exact path="/ceelorake" element={<Ceelorake />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    </>
  );
}

export default App;
