import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  const [MenuOpen, setMenuOpen] = useState(false);
  const [showntm, setShowbtn] = useState(true);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/employee") {
      setShowbtn(false);
    } else {
      setShowbtn(true);
    }
    if (pathname === "/ceelorake") {
      setShowbtn(false);
    } else {
      setShowbtn(true);
    }
  }, [pathname]);
  return (
    <>
      <div className="header">
        <div className={`${!showntm ? 'container-fluid px-0' : 'container'}`}>
          {!showntm &&
              <div className="top-bar">
                <h5>Harlem Gaming Company LLC</h5>
                <h5> Cyril Swavy</h5>
                <h5> 646 250 1831</h5>
                <h5> 456celodice@gmail.com</h5>
              </div>
          }
          <div className="header-bottom">
            <div
              className={`header-bottom-area align-items-center ${showntm ? "justify-content-between" : ""
                } `}
            >
              <div className="logo">
                <Link to="/">
                  <img src="./assets/images/logo.png" alt="logo" />
                </Link>
              </div>
              {showntm && (
                <>
                  <ul className={`${MenuOpen ? "menu active" : "menu"}`}>
                    <li>
                      <Link
                        to="/employee"
                        className="cmn--btn active btn--lg"
                        style={{ fontSize: "15px" }}
                      >
                        The download rules for Casino managers and Directors
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/ceelorake"
                        className="cmn--btn active btn--lg ms-3"
                        style={{ fontSize: "15px" }}
                      >
                        Cee lo Rake
                      </Link>
                    </li>
                    {/* <li>
                                    <a href="about.html">About</a>
                                </li>
                                <li>
                                    <Link to="/employee">Employee</Link>
                                </li>
                                <li>
                                    <a href="games">Games <span className="badge badge--sm badge--base text-dark">NEW</span></a>
                                </li>
                                <li>
                                    <a href="faq.html">Faq</a>
                                </li>
                                <li>
                                    <a href="contact.html">Contact</a>
                                </li> */}
                    <button
                      className="btn-close btn-close-white d-lg-none"
                      onClick={() => setMenuOpen(false)}
                    ></button>
                  </ul>

                  <div
                    className="header-trigger-wrapper d-flex d-lg-none align-items-center"
                    onClick={() => setMenuOpen(true)}
                  >
                    <div className="header-trigger me-4">
                      <span></span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
