import React from "react";

const WhyChooseUsCeelorake = () => {
  return (
    <>
      <section
        className="why-section padding-top overflow-hidden"
        style={{ marginTop: "200px" }}
      >
        <div className="container">
          <div className="row justify-content-between gy-5">
            <div className="col-lg-12 col-xl-12">
              <div class="section-header text-center">
                <h2 class="section-header__title"> Rules of Play</h2>
                <h6>BETS ON THE HOUSE OR PLAYER</h6>
              </div>
              <div className="row gy-4 gy-md-5 gy-lg-4 gy-xl-5">
                <div className="col-lg-12 col-sm-6">
                  <div>
                    <div className="why-item__content">
                      <h4 className="title">01.The Banker</h4>
                      <b>{``}</b>&nbsp;
                      <span>{`will begin rolling the dice to establish a point, or an automatic winner, or automatic loser.`}</span>
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-6">
                  <div className="">
                    <div className="why-item__content">
                      <h4 className="title">02.Automatic winners</h4>
                      <p>
                        {" "}
                        are 456, any triple , (111,222,333,444,555,666) or any
                        pair and a 6 (116,226,336,446,556) &nbsp;
                        <b>{`Automatic Losers`}</b>&nbsp; are 123 and any pair
                        and a1 (221, 331,441,551,661) &nbsp;
                        <b>{`Point decision`}</b>&nbsp; will mean any pair where
                        the third die is 2,3,4,5.
                      </p>
                      &nbsp;
                    </div>
                  </div>
                </div>
                <div className="col-lg-12 col-sm-6">
                  <div className="">
                    <div className="why-item__content">
                      <h4 className="title">All Non decisions are ignored</h4>
                      <p>
                        {`The `}&nbsp;
                        <b>{`House`}</b>&nbsp; will allow the first person
                        clockwise of Stick man/ Dealer to put money on &nbsp;
                        <b>{`Banker`}</b>&nbsp; Banker to take the bank. Once
                        the &nbsp;<b>{`House`}</b>&nbsp; gets a Banker, the
                        &nbsp;<b>{`House`}</b>&nbsp; will control the &nbsp;
                        <b>{`Banker’s`}</b>&nbsp; money in the Banker Bank Box/
                        Winning/Push.
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker</b> can only roll the dice if he has a
                        player, or players betting against him.
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker’s</b> bank will cover the players bet. The
                        players are allowed to bet as high as whatever is in the
                        <b>Banker’s</b> bank. If the money doesn’t reach (able
                        to cover) the bet, that potion that it doesn’t cover
                        will not be a bet.
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker</b>cannot remove money out of his bank
                        once a decision is rolled. 
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker</b> can only add money to his bank, when
                        the dice doesn’t have a decision rolled yet and he still
                        has money in his bank
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker</b>cannot cut his bank unless he rolls 456
                        for a decision. If he rolls 456, the house will take the
                        players money that the <b>Banker</b> can cover then take
                        their 5% from the <b>Banker </b> winning bets.
                      </p>
                      &nbsp;
                      <p>
                        The <b>Banker</b>can pass his Bank at any time when
                        there has been no decision thrown.
                      </p>
                      &nbsp;
                      <p>
                        If The <b>Banker</b> rolls a point, all the players that
                        the <b>Banker’s</b> bank can cover will get to roll the
                        dice against the <b>Banker’s</b> point. All player bets
                        will be paid even money for which the Banker can cover.
                      </p>
                      &nbsp;
                      <p>
                        The first player who rolls 456 against the <b>Banker</b>{" "}
                        point, will have the option to take the <b>Bank</b>{" "}
                        after all players shoot the dice. Player bets will be
                        covered in order, clockwise of the <b>Banker</b>. The
                        first persons clockwise of the <b>Banker</b> in that
                        order will have a bet if the <b>Banker</b> can cover
                        their bets.
                      </p>
                      &nbsp;
                      <p>
                        If the player who rolled 456 doesn’t want the bank, the
                        Bank will be offered to the <b>next</b> person clockwise of
                        that player who rolled 456. Example, if the <b>Banker</b> has
                        100 dollars in his bank, and the first person clockwise
                        of him bets 50 dollars, and the next two people bet 50
                        dollars,  only the first two people bets will be covered
                        clockwise of the <b>Banker</b>, the 3rd person will not have a
                        bet.
                      </p>
                      &nbsp;
                      <p>
                      If the <b>banker</b> loses all of his money at one time, then the <b>banker</b> must pass the bank to the next Person going clockwise of him betting on <b>banker</b>. Push/winnings.  If the <b>banker</b> has a 3 for a point, and he has two people betting 50 dollars clockwise of him, both parties will shoot to try to beat the point.  If shooter number one throws the same point that will be considered a push/tie, with <b>banker</b>. The dealer will remove 50 dollars from the <b>banker</b>s Bank, and put it in the Push/winning box. Then the next person will roll the dice for the other 50 dollars and so forth. Push/tie money is always removed to winnings and push box when this type of situation occurs.
                      </p>
                      &nbsp;
                      <p>
                      The player can remove money from the player bet when the dice don’t have a decision yet. Whenever the <b>banker</b> beats a player, the house will make sure he can cover bet, then take the money from player, take their 5% cut, and put all winnings in push/ winnings box after they take a 5% cut.
                      </p>
                      &nbsp;
                      <p>
                      Example, if the <b>banker</b> rolls a 4 for a point, and the player is betting 50 dollars, and the <b>banker</b> can cover that potion, house will take the player money, take out 5% Vig, then remove 50 dollars from <b>banker</b>s Bank as well to Match the money he just won.  Therefore the <b>banker</b> will have 50 dollars plus 47.50 in the push/ winning box. Which is 97.50 total in the winning box.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="shapes">
          <img
            src="./assets/images/why/shape.png"
            alt="why"
            className="shape shape1"
          />
        </div>
      </section>
    </>
  );
};

export default WhyChooseUsCeelorake;
