import React from 'react'
import Header from './Header'
import Footer from './Footer'

const Layout = ({ children }) => {
    // const [loader, setLoader] = useState(false)

    // useEffect(() => {
    //     const hasPageLoadedBefore = localStorage.getItem('hasPageLoadedBefore');

    //     if (!hasPageLoadedBefore) {
    //         // If it's the first visit, show the loader
    //         setLoader(true);
    //         // Set the flag to indicate that the page has been loaded
    //         localStorage.setItem('hasPageLoadedBefore', 'true');
    //     } else {
    //         // If the page has been loaded before, don't show the loader
    //         setLoader(false);
    //     }
    // }, [])
    return (
        <>
            <Header />
            {children}
            <Footer />

            {/* {loader ?
                <>
                    <div className="overlay"></div>
                    <div className="preloader">
                        <div className="scene" id="scene">
                            <input type="checkbox" id="andicator" />
                            <div className="cube">
                                <div className="cube__face cube__face--front"><i></i></div>
                                <div className="cube__face cube__face--back"><i></i><i></i></div>
                                <div className="cube__face cube__face--right">
                                    <i></i> <i></i> <i></i> <i></i> <i></i>
                                </div>
                                <div className="cube__face cube__face--left">
                                    <i></i> <i></i> <i></i> <i></i> <i></i> <i></i>
                                </div>
                                <div className="cube__face cube__face--top">
                                    <i></i> <i></i> <i></i>
                                </div>
                                <div className="cube__face cube__face--bottom">
                                    <i></i> <i></i> <i></i> <i></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                <>
                    <Header />
                    {children}
                    <Footer />
                </>
            } */}
        </>
    )
}

export default Layout